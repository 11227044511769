.sideBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  &__logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 150px;

    &__active {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.iconSideBar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.iconSideBarActive {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 150px;
}


.ant-tooltip-inner {
  color: #FFFFFF;
  background-color: #000000 ;
}
