.customerTableTreso {
  background-color: #33cc00 !important;
  color: #ffffff !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}
.customerTableCompta {
  background-color: #3399cc !important;
  color: #ffffff !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}

.columnStyle {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}
.ColoumnHeaderTitle {
  border-right: 7px solid #f5f5f5 !important;
  background-color: #f5f5f5 !important;
  color: #000000 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
}

.customerColoumn {
  border-right: 7px solid #f5f5f5 !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.ant-table-thead {
  .customerColoumnMois {
    border-right: 1px solid #f5f5f5 !important;
    background-color: #ffffff !important;
    color: #000000 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
}
.ant-table-tbody > tr.ant-table-row-level-0 > td.customerColoumnMois {
  text-decoration: underline;
}
.ant-table-tbody {
  .customerColoumnMois {
    border-right: 1px solid #f5f5f5 !important;
    color: #000000 !important;
    font-family: 'Inter';
    font-style: normal;
    align-items: flex-end !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: lowercase;
  }
  .customerColoumn {
    border-right: 7px solid #f5f5f5 !important;
    //background-color: red !important;
    color: #000000 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.ajoutBudget {
  width: 30px;
  cursor: pointer;
}
.ajoutBSousudget {
  cursor: pointer;
}

.editInput {
  padding-right: 10%;
  margin: 0;
}
.actionStyle {
  background-color: #ffffff !important;
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after {
  box-shadow: none;
  border: none;
}

.editable-cell {
  position: relative;
}
.addCategorie {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editable-cell-value-wrap {
  width: 100%;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  padding: 4px 11px;
}

.expandedIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__simulation {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

    button {
      width: 100%;
    }
  }
  &__reelle {
    display: flex;
    justify-content: center;

    button {
      width: 100%;
    }
  }
}

/* .ant-table-tbody .ant-table-cell {
  background-color: #3399cc !important;
}
.treso .ant-table-tbody .ant-table-cell {
  background-color: green !important;
} */

.btn-confirmation {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 20px;

  Button {
    background-color: #787878;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
}

/* .ant-table-tbody > tr.ant-table-row-level-0 > td.gray {
  background-color: #787878;
} */
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
}
/*  .ant-table-tbody > tr.ant-table-row > td:hover {
  background-color: transparent !important;
}
 */
.green {
  background-color: #33cc00 !important;
}

.gray1 {
  background-color: rgba(154, 154, 154, 0.65) !important;
}
.gray2 {
  background-color: #e7e7e7 !important;
}
.gray3 {
  background-color: #e4e7eb !important;
}
.gray4 {
  background-color: #f8f8f8 !important;
}
.gray5 {
  background-color: #f0f0f0 !important;
}

.blue {
  background-color: #3399cc !important;
}

.customStatut {
  background-color: red;
}
.contentPopver {
  display: flex;
  justify-content: space-between;
}
.contentDelete {
  &__delete img {
    width: 24px;
    height: 24px;
  }
}
.btexpanded {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.ant-table-body table {
  padding-bottom: 10px;
}

.ant-table-tbody {
  min-height: 88vh;
  height: auto;
}

@media only screen and (min-width: 1900px) {
  .ant-table-tbody {
    min-height: 77vh;
  }
}

.btdelete {
  background-color: transparent;
  height: 26px;
  img {
    width: 24px;
    height: 24px;
  }
  button {
    background-color: transparent;
    border: transparent;
    cursor: pointer;
  }
}
.cellMontant {
  height: 30px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
