.hoverText {
  background-color: black !important;
  width: 100%;
  height: 5px;
  z-index: 6;
  display: flex;
  align-items: center;
}

.cellCustomer {
  width: 100%;
  height: 25px;
}
.ajoutElement {
  position: relative;
  top: 6px;
}
.ajoutEcriture {
  display: flex;
  align-items: flex-end;
  width: 183px;
  height: 35px;
  position: absolute;
  left: 1px;
}
.popover-add-element {
  .ant-popover-inner {
    background-color: #dedfde;
  }
}
.popup-ajouterEcriture {
  display: flex;
  align-items: center;
  justify-content: center;
}

.relle {
  background-color: rgba(51, 153, 204, 0.5);
}
.ant-btn-primary:focus {
  background-color: red !important;
}

.btadd {
  background-color: transparent;
  border: none;
}
.popoverAddElement {
  .ant-popover-inner {
    background-color: #F2F2F2;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.dateElement{
  padding-bottom: 10px;
}

.btn_ConfirmationElement {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-bottom: 10px;
  Button {
    width: 90px;
    height: 35px;
    border: 0.5px solid #000000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
  }
}
.annulerElement {
  background-color: #f2f2f2;
  color: #000000;
}
.validerElement {
  background-color: #000000;
  color: #ffffff;
}